import { MaterialIcons } from '@expo/vector-icons'
import { IconButton, View, Text, Box, Row } from 'native-base'
import React /* , { useContext } */ from 'react'
import { useTranslation } from 'react-i18next'

import EmailForgottenSvg from 'components/EmailForgottenSvg'
// import { analytics } from 'services/analytics'
// import { AnalyticsKey } from 'services/analytics/types'
// import { IntercomContext } from 'services/chat/provider'
import { IS_WEB } from 'services/platform'
import { Color } from 'theme'
import { RootScreenProps } from 'types/navigation'

type Props = RootScreenProps<'EmailForgotten'>

export const EmailForgottenScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation()
  // const { openChat, setChatLoading } = useContext(IntercomContext)

  // TODO: Add back chat button

  // function handleOpenChat() {
  //   if (isWebView()) {
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_CHAT_EVENT' }))
  //   } else {
  //     analytics.log(AnalyticsKey.OPEN_CHAT)
  //     setChatLoading(true)
  //     openChat()
  //   }
  // }

  return (
    <View
      width="100%"
      minW="300px"
      maxW="800px"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <IconButton
        position="absolute"
        top={0}
        left={0}
        size="sm"
        variant="ghost"
        onPress={() => navigation.navigate('SignIn')}
        icon={<MaterialIcons color={Color.blueNight} name="keyboard-arrow-left" size={48} />}
      />

      <Box alignItems="center" marginTop={100}>
        <Text fontSize="2xl" fontWeight="medium" textAlign="center" padding={5}>
          {t('emailForgotten.title')}
        </Text>
        <Box>
          <EmailForgottenSvg />
        </Box>
      </Box>

      <Box h={8} />

      <Row marginLeft={!IS_WEB ? 7 : 100} marginRight={12}>
        <Text fontSize="xl" fontWeight="semibold" color={Color.yellow}>
          1
        </Text>
        <Box w={5} />
        <Text fontSize="sm" style={{ marginTop: 5 }}>
          {t('emailForgotten.firstStep')}
        </Text>
      </Row>
      <Box h={4} />
      <Row marginLeft={!IS_WEB ? 7 : 100} marginRight={12}>
        <Text fontSize="xl" fontWeight="semibold" color={Color.yellow}>
          2
        </Text>
        <Box w={5} />
        <Text fontSize="sm" style={{ marginTop: 5 }}>
          {t('emailForgotten.secondStep')}
        </Text>
      </Row>
    </View>
  )
}
